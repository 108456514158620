<template>
  <div>
    <v-simple-table height="calc(100vh - 145px)" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr v-if="errTexts.length > 0" style="font-size: 12px">
            <td colspan="13">
              <div class="yellow error--text pa-3">
                <ul>
                  <li
                    v-for="(txt, ti) in errTexts"
                    :key="`txterr_${ti}`"
                    v-html="txt"
                  ></li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <th role="columnheader" class="text-center vertical-align-middle">
              #
            </th>
            <th
              role="columnheader"
              class="text-center vertical-align-middle"
              v-if="customer.is_multiple_shop"
            >
              {{ $t("labels.shop") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.customer_order_id") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.receiver") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.phone_number") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.city") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.district") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.ward") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.address") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.cash_on_delivery") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.note") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.product") }}
            </th>
            <template v-if="createFormat === 'ssc'">
              <th role="columnheader" class="text-center vertical-align-middle">
                {{ $t("labels.delivery_check_goods") }}
              </th>
              <th role="columnheader" class="text-center vertical-align-middle">
                {{ $t("labels.delivery_partial") }}
              </th>
              <th role="columnheader" class="text-center vertical-align-middle">
                {{ $t("labels.delivery_return_required") }}
              </th>
              <th role="columnheader" class="text-center vertical-align-middle">
                {{ $t("labels.delivery_payment_by_customer_1") }}
              </th>
            </template>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.delivery_company") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.delivery_order_id") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.id"
            class="text-center"
            :class="{ 'error--text': errRows.includes(item.tt) }"
          >
            <td>{{ item.tt }}</td>
            <td v-if="customer.is_multiple_shop">{{ item.shop }}</td>
            <td>{{ item.customer_order_id }}</td>
            <td>{{ item.receiver_name }}</td>
            <td>{{ item.receiver_phone_number }}</td>
            <td>{{ item.city_name }}</td>
            <td>{{ item.county_name }}</td>
            <td>{{ item.ward_name }}</td>
            <td>{{ item.address }}</td>
            <td>{{ formatNumber(item.cash_on_delivery) }}</td>
            <td>{{ item.note }}</td>
            <td>
              <div
                v-for="(goods, gi) in item.goods_items || []"
                :key="`${index}_${gi}_${goods.barcode}`"
              >
                {{ goods.barcode }} x {{ goods.quantity }}
              </div>
            </td>
            <template v-if="createFormat === 'ssc'">
              <td>
                <v-icon v-if="item.delivery_check_goods">mdi-close</v-icon>
              </td>
              <td>
                <v-icon v-if="item.delivery_partial">mdi-close</v-icon>
              </td>
              <td>
                <v-icon v-if="item.delivery_return_required">mdi-close</v-icon>
              </td>
              <td>
                <v-icon v-if="item.receiver_pay_for_delivery_cost"
                  >mdi-close</v-icon
                >
              </td>
            </template>
            <td>{{ item.delivery_company }}</td>
            <td>{{ item.delivery_order_id }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3 d-flex justify-space-between align-center">
      <div class="d-flex justify-start align-center">
        <v-autocomplete
          class="c-input-xs mr-2"
          style="max-width: 180px"
          v-model="createFormat"
          :items="createFormatOptions"
          label="Format"
          placeholder="Format"
          dense
          outlined
          hide-details
        ></v-autocomplete>
        <SelectWarehouse
          :label="$t('labels.warehouse_1')"
          :has-filter="false"
          name="id_warehouse"
          class="mr-2"
          @onFilter="onWarehouseChange"
        />
        <a
          class="mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default default"
          :href="templateLink"
          target="_blank"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.sample_file") }}
        </a>
        <v-btn color="info" @click="$refs.inputUploadFile.click()"
          ><v-icon>mdi-upload</v-icon>
          {{ $t("labels.upload") }}
        </v-btn>
        <input
          type="file"
          ref="inputUploadFile"
          accept=".xlsx"
          @change="onInputFileChange"
          class="d-none"
        />
        <div
          class="error--text ml-3"
          style="font-size: 12px; line-height: 14px"
        >
          {{ $t("labels.order_merge_info") }}
          {{ $t("labels.order_merge_details") }}
        </div>
      </div>
      <div class="d-flex justify-end align-center" style="min-width: 300px">
        <span class="fs-14px mr-2">{{
          $t("labels.sum_order", { count: items.length })
        }}</span>
        <v-btn
          color="success"
          @click="createOrders"
          :disabled="isDisabledBtnSubmit"
        >
          <v-icon>mdi-check</v-icon> {{ $t("labels.create_order") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import { httpClient } from "@/libs/http";
import _ from "lodash";

export default {
  name: "CreateExcel",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    shops: [],
    customer: {},
    page: 1,
    totalPage: 1,
    errRows: [],
    errTexts: [],
    deliveryCompanyNames: [],
    createFormatOptions: [
      {
        value: "ssc",
        text: "SSC",
      },
      {
        value: "kiotviet",
        text: "Kiotviet",
      },
      {
        value: "sapo",
        text: "Sapo",
      },
      {
        value: "nhanh",
        text: "Nhanh",
      },
      {
        value: "pancake",
        text: "Pancake",
      },
    ],
    createFormat: "ssc",
    idWarehouse: null,
    file: null,
  }),
  computed: {
    isDisabledBtnSubmit() {
      return (
        this.items.length === 0 || this.errRows.length > 0 || !this.idWarehouse
      );
    },
    templateLink() {
      if (this.customer.is_multiple_shop) {
        return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_create_orders_multiple_shop.xlsx`;
      }
      return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_create_orders.xlsx`;
    },
  },
  created() {
    const { customer } = window.me;
    this.customer = { ...customer };
  },
  mounted() {
    this.getDeliveryCompany();
    // this.getShop();
  },
  methods: {
    onWarehouseChange(item) {
      this.idWarehouse = item.value;
    },
    readBySsc(values) {
      const mappingFields = {
        "Tên ShopShop": "shop",
        "Mã đơn hàngOrder ID": "customer_order_id",
        "Tên người gửiSender": "custom_sender_name",
        "Điện thoại người gửiSender Number": "custom_sender_phone",
        "Người NhậnReceiver": "receiver_name",
        "Số Điện ThoạiReceiver Number": "receiver_phone_number",
        "Tỉnh thànhProvince": "city_name",
        "Quận huyệnCity|Municipal": "county_name",
        "Phường xãBarangay|Ward": "ward_name",
        "Địa chỉAddress": "address",
        "Tiền thu hộCash on Delivery": "cash_on_delivery",
        "Ghi ChúNote": "note",
        BarcodeBarcode: "barcodes",
        "Số LượngQuantity": "quantities",
        "Xem hàngCheck Product Allow": "delivery_check_goods",
        "Giao hàng một phầnPartial delivery": "delivery_partial",
        "Đổi hàngExchange": "delivery_return_required",
        "Người nhận trả phí vận chuyểnReceiver pays shipping fees":
          "receiver_pay_for_delivery_cost",
        "Hãng vận chuyểnDelivery Company": "delivery_company",
        "Mã vận chuyểnDelivery Tracking": "delivery_order_id",
      };
      let items = [...values].map((v, index) => {
        const item = {
          tt: index + 1,
        };
        Object.keys(v).forEach((i) => {
          const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
          const mapkey = i.replace(/\r?\n|\r/g, "");
          item[mappingFields[mapkey]] = Buffer.from(
            `${val}`,
            "utf-8"
          ).toString();
        });
        const barcodes = (item.barcodes && item.barcodes.split(";")) || [];
        const quantities =
          (item.quantities && item.quantities.split(";")) || [];
        item.customer_order_id =
          item.customer_order_id || this.generateRandomString(8);
        item.delivery_check_goods =
          item.delivery_check_goods &&
          item.delivery_check_goods.toLowerCase() === "x"
            ? 1
            : 0;
        item.delivery_partial =
          item.delivery_partial && item.delivery_partial.toLowerCase() === "x"
            ? 1
            : 0;
        item.delivery_return_required =
          item.delivery_return_required &&
          item.delivery_return_required.toLowerCase() === "x"
            ? 1
            : 0;
        item.receiver_pay_for_delivery_cost =
          item.receiver_pay_for_delivery_cost &&
          item.receiver_pay_for_delivery_cost.toLowerCase() === "x"
            ? 1
            : 0;
        const goods_items = [...barcodes].map((b, i) => {
          return {
            barcode: (b && b.trim()) || "",
            quantity: +((quantities[i] && quantities[i].trim()) || ""),
          };
        });
        item.goods_items = [...goods_items];
        return item;
      });

      let data = [];
      let tt = 1;
      items.forEach((item) => {
        const checkItem = data.find(
          (d) => d.customer_order_id == item.customer_order_id
        );
        if (checkItem) {
          const checkItemIndex = data.findIndex(
            (d) => d.customer_order_id == item.customer_order_id
          );
          const checkGoodsBarcodes =
            [...checkItem.goods_items].map((ci) => ci.barcode) || [];

          const goodsItems =
            [...item.goods_items].filter((gi) =>
              checkGoodsBarcodes.includes(gi.barcode)
            ) || [];
          if (goodsItems && goodsItems.length > 0) {
            goodsItems.forEach((gi) => {
              const checkGoodsIndex = [...checkItem.goods_items].findIndex(
                (cgi) => cgi.barcode == gi.barcode
              );
              checkItem.goods_items[checkGoodsIndex].quantity += gi.quantity;
            });
          }

          const goodsNoItems =
            [...item.goods_items].filter(
              (gi) => !checkGoodsBarcodes.includes(gi.barcode)
            ) || [];
          if (goodsNoItems && goodsNoItems.length > 0) {
            checkItem.goods_items.push(...goodsNoItems);
          }

          data[checkItemIndex] = checkItem;
        } else {
          item.tt = tt;
          data.push(item);
          tt++;
        }
      });
      data = this.updateDeliveryCompany(data);
      this.items = [...data];
      this.validateData(data);
    },
    readByKiotviet(values) {
      const mappingFields = {
        "Mã hóa đơn": "customer_order_id",
        "Người nhận": "receiver_name",
        "Điện thoại (Người nhận)": "receiver_phone_number",
        "Khu vực (Người nhận)": "city_name",
        "Phường/Xã (Người nhận)": "ward_name",
        "Địa chỉ (Người nhận)": "address",
        "Còn cần thu (COD)": "cash_on_delivery",
        "Ghi chú": "note",
        "Mã hàng": "barcodes",
        "Số lượng": "quantities",
        "Đối tác giao hàng": "delivery_company",
        "Mã vận đơn": "delivery_order_id",
      };
      let items = [...values].map((v) => {
        const item = {};
        Object.keys(v).forEach((i) => {
          if (mappingFields[i]) {
            const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
            item[mappingFields[i]] = Buffer.from(`${val}`, "utf-8").toString();
          }
        });
        const barcodes = (item.barcodes && item.barcodes.split(";")) || [];
        const quantities =
          (item.quantities && item.quantities.split(";")) || [];
        const city_county = item.city_name.split("-").map((r) => r.trim());

        item.county_name = city_county[city_county.length - 1];
        city_county.splice(city_county.length - 1, 1);
        item.city_name = city_county.join(" - ");

        const goods_items = [...barcodes].map((b, i) => {
          return {
            barcode: (b && b.trim()) || "",
            quantity: (quantities[i] && quantities[i].trim()) || "",
          };
        });
        item.goods_items = [...goods_items];
        return item;
      });
      let data = [];
      let tt = 1;
      items.forEach((item) => {
        const checkItem = data.find(
          (d) => d.customer_order_id == item.customer_order_id
        );
        if (checkItem) {
          const checkItemIndex = data.findIndex(
            (d) => d.customer_order_id == item.customer_order_id
          );
          const checkItemGoods = checkItem.goods_items.filter(
            (g) => g.barcode == item.barcode
          );
          if (!checkItemGoods || checkItemGoods.length === 0) {
            checkItem.goods_items.push(...item.goods_items);
          } else {
            const checkItemGoodsIndex = checkItem.goods_items.findIndex(
              (g) => g.barcode == item.barcode
            );
            let quantity = +checkItem.goods_items[checkItemGoodsIndex].quantity;
            let barcode = checkItem.goods_items[checkItemGoodsIndex].barcode;
            quantity = quantity + +item.quantity;
            checkItem.goods_items[checkItemGoodsIndex] = {
              barcode,
              quantity,
            };
          }
          data[checkItemIndex] = { ...checkItem };
        } else {
          item.tt = tt;
          data.push(item);
          tt++;
        }
      });
      data = this.updateDeliveryCompany(data);
      this.items = [...data];
      this.validateData(data);
    },
    readBySapo(values) {
      const mappingFields = {
        "Mã đơn hàng": "customer_order_id",
        "Tên người nhận": "receiver_name",
        "SĐT người nhận": "receiver_phone_number",
        "Tỉnh/Thành": "city_name",
        "Quận/Huyện": "county_name",
        "Phường xã": "ward_name",
        "Địa chỉ giao hàng": "address",
        "Tổng tiền thu hộ": "cash_on_delivery",
        "Ghi chú đơn giao": "note",
        "Mã sản phẩm": "barcodes",
        "Số lượng": "quantities",
        "Đối tác giao hàng": "delivery_company",
        "Mã vận đơn": "delivery_order_id",
      };
      let items = [...values].map((v) => {
        const item = {};
        Object.keys(v).forEach((i) => {
          if (mappingFields[i]) {
            const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
            item[mappingFields[i]] = Buffer.from(`${val}`, "utf-8").toString();
          }
        });
        const barcodes = (item.barcodes && item.barcodes.split(";")) || [];
        const quantities =
          (item.quantities && item.quantities.split(";")) || [];
        const goods_items = [...barcodes].map((b, i) => {
          return {
            barcode: (b && b.trim()) || "",
            quantity: (quantities[i] && quantities[i].trim()) || "",
          };
        });
        item.goods_items = [...goods_items];
        return item;
      });
      let data = [];
      let tt = 1;
      items.forEach((item) => {
        if (item.customer_order_id) {
          item.tt = tt;
          data.push(item);
          tt++;
        } else {
          const lastIndex = data.length - 1;
          data[lastIndex].goods_items.push(...item.goods_items);
        }
      });
      data = this.updateDeliveryCompany(data);
      this.items = [...data];
      this.validateData(data);
    },
    readByNhanh(values) {
      const mappingFields = {
        ID: "customer_order_id",
        "Tên khách hàng": "receiver_name",
        "Số điện thoại": "receiver_phone_number",
        "Thành phố_1": "city_name",
        "Quận huyện_1": "county_name",
        "Phường xã": "ward_name",
        "Địa chỉ": "address",
        "Tổng thu": "cash_on_delivery",
        "Ghi chú của khách": "note",
        "Mã vạch": "barcodes",
        "Số lượng": "quantities",
        "Hãng vận chuyển": "delivery_company",
        "Mã đơn hãng vận chuyển": "delivery_order_id",
      };
      let items = [...values].map((v) => {
        const item = {};
        Object.keys(v).forEach((i) => {
          if (mappingFields[i]) {
            const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
            item[mappingFields[i]] = Buffer.from(`${val}`, "utf-8").toString();
          }
        });
        const barcodes = (item.barcodes && item.barcodes.split(";")) || [];
        const quantities =
          (item.quantities && item.quantities.split(";")) || [];
        const goods_items = [...barcodes].map((b, i) => {
          return {
            barcode: (b && b.trim()) || "",
            quantity: (quantities[i] && quantities[i].trim()) || "",
          };
        });
        item.goods_items = [...goods_items];
        return item;
      });
      let data = [];
      let tt = 1;

      items.forEach((item) => {
        if (item.customer_order_id) {
          const checkItem = data.find(
            (d) => d.customer_order_id == item.customer_order_id
          );
          if (checkItem) {
            const checkItemIndex = data.findIndex(
              (d) => d.customer_order_id == item.customer_order_id
            );
            const checkItemGoods = checkItem.goods_items.filter(
              (g) => g.barcode == item.barcode
            );
            if (!checkItemGoods || checkItemGoods.length === 0) {
              checkItem.goods_items.push(...item.goods_items);
            } else {
              const checkItemGoodsIndex = checkItem.goods_items.findIndex(
                (g) => g.barcode == item.barcode
              );
              let quantity =
                +checkItem.goods_items[checkItemGoodsIndex].quantity;
              let barcode = checkItem.goods_items[checkItemGoodsIndex].barcode;
              quantity = quantity + +item.quantity;
              checkItem.goods_items[checkItemGoodsIndex] = {
                barcode,
                quantity,
              };
            }
            data[checkItemIndex] = { ...checkItem };
          } else {
            item.tt = tt;
            data.push(item);
            tt++;
          }
          /* item.tt = tt
          data.push(item)
          tt++ */
        } else {
          const lastIndex = data.length - 1;
          data[lastIndex].goods_items.push(...item.goods_items);
        }
      });
      data = this.updateDeliveryCompany(data);
      this.items = [...data];
      this.validateData(data);
    },
    readByPancake(values) {
      const mappingFields = {
        "Mã đơn hàng đầy đủ": "customer_order_id",
        "Người nhận": "receiver_name",
        SĐT: "receiver_phone_number",
        "Tỉnh thành phố": "city_name",
        "Quận/Huyện": "county_name",
        "Phường/Xã": "ward_name",
        "Địa chỉ": "address",
        COD: "cash_on_delivery",
        "Ghi chú của khách": "note",
        "Mã sản phẩm": "barcodes",
        "Số lượng": "quantities",
        "Đơn vị VC": "delivery_company",
        "Mã vận đơn": "delivery_order_id",
        "Đơn giá sau giảm giá": "unit_price",
        "Tên sản phẩm": "item_name",
        // "Đơn giá sau giảm giá": "total_amount",
      };
      let items = [...values].map((v) => {
        const item = {};
        Object.keys(v).forEach((i) => {
          if (mappingFields[i]) {
            const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
            item[mappingFields[i]] = Buffer.from(`${val}`, "utf-8").toString();
          }
        });
        const barcodes = (item.barcodes && item.barcodes.split(";")) || [];
        const quantities =
          (item.quantities && item.quantities.split(";")) || [];
        const goods_items = [...barcodes].map((b, i) => {
          const barcode = (b && b.trim()) || "";
          const quantity = (quantities[i] && quantities[i].trim()) || "";
          const unit_price = +item.unit_price;
          const name = item.item_name;
          const total_amount = unit_price * quantity;
          return {
            barcode,
            quantity,
            unit_price,
            total_amount,
            name,
          };
        });
        item.goods_items = [...goods_items];
        return item;
      });
      let data = [];
      let tt = 1;
      items.forEach((item) => {
        if (item.customer_order_id) {
          item.tt = tt;
          data.push(item);
          tt++;
        } else {
          const lastIndex = data.length - 1;
          data[lastIndex].goods_items.push(...item.goods_items);
        }
      });
      data = this.updateDeliveryCompany(data);
      console.log("data", data);
      this.items = [...data];
      this.validateData(data);
    },
    async onInputFileChange(e) {
      this.errRows = [];
      this.errTexts = [];
      const files = e.target.files;
      if (files && files[0]) {
        const f = files[0];
        this.file = f;
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let values = [];
          switch (this.createFormat) {
            case "ssc":
              values = utils.sheet_to_json(worksheet);
              this.readBySsc(values);
              break;
            case "kiotviet":
              values = utils.sheet_to_json(worksheet);
              this.readByKiotviet(values);
              break;
            case "sapo":
              worksheet["!ref"] = worksheet["!ref"].replace("A2:", "A18:");
              values = utils.sheet_to_json(worksheet);
              this.readBySapo(values);
              break;
            case "nhanh":
              values = utils.sheet_to_json(worksheet);
              this.readByNhanh(values);
              break;
            case "pancake":
              values = utils.sheet_to_json(worksheet);
              this.readByPancake(values);
              break;
          }
        };
        await reader.readAsArrayBuffer(f);
      }
    },

    updateDeliveryCompany(items) {
      items = [...items].map((item) => {
        if (item.delivery_company) {
          const checkDelivery = this.deliveryCompanyNames.find((d) =>
            [
              d.name.toLowerCase(),
              d.possible_name.toLowerCase(),
              d.short_name.toLowerCase(),
            ].includes(item.delivery_company.toLowerCase())
          );
          if (checkDelivery) {
            item.tvc = 1;
            item.delivery_company = checkDelivery.name;
            item.id_delivery_company = checkDelivery.id_delivery_company;
          }
        }
        return item;
      });
      return items;
    },
    validateData(items) {
      const groupByCustomerOrderId = _.groupBy(items, "customer_order_id");
      Object.keys(groupByCustomerOrderId).forEach((k) => {
        if (groupByCustomerOrderId[k].length > 1) {
          const rows = groupByCustomerOrderId[k].map((g) => g.tt);
          this.errRows.push(...rows);
          this.errTexts.push(
            `<b>[${this.$t("labels.customer_order_id")}]</b>: "${
              k === "undefined" ? "" : k
            }" ${this.$t(
              "labels.duplicate"
            )} : <span class="error--text">${rows.join(", ")}</span>`
          );
        }
      });

      const requiredKeys = [
        "customer_order_id",
        "receiver_name",
        "receiver_phone_number",
        "city_name",
        "county_name",
        "ward_name",
        "address",
        "goods_items",
        //'delivery_company',
      ];

      if (this.customer.is_multiple_shop) {
        requiredKeys.unshift("shop");
      }

      const requiredKeyTrans = {
        shop: this.$t("labels.shop"),
        customer_order_id: this.$t("labels.customer_order_id"),
        receiver_name: this.$t("labels.receiver"),
        receiver_phone_number: this.$t("labels.phone_number"),
        city_name: this.$t("labels.city"),
        county_name: this.$t("labels.district"),
        ward_name: this.$t("labels.ward"),
        address: this.$t("labels.address"),
        goods_items: this.$t("labels.product"),
        //'delivery_company': 'Hãng vận chuyển',
      };
      requiredKeys.forEach((i) => {
        const rows = items.filter((item) => !item[i] || item[i].length === 0);
        if (rows.length > 0) {
          const iRows = [...rows].map((r) => r.tt);
          this.errRows.push(...iRows);
          this.errTexts.push(
            `<b>[${requiredKeyTrans[i]}]</b>: ${this.$t(
              "labels.invalid_value"
            )}: <span class="error--text">${iRows.join(", ")}</span>`
          );
        }
      });
    },
    async createOrders() {
      if (this.isDisabledBtnSubmit) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        let fd = new FormData();
        fd.append("file", this.file);
        fd.append("orders", JSON.stringify(this.items));
        fd.append("create_method", 2);
        fd.append("id_warehouse", this.idWarehouse);
        await httpClient.post("/orders-create", fd);
        this.items = [];
        this.errRows = [];
        this.errTexts = [];
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.order_created_success"));
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates = (e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.validates) || { errRows: [], errTexts: [] };
        if (validates && validates.errRows && validates.errTexts) {
          const lang = localStorage.getItem("_lang") || "vi";
          this.errRows = [...validates.errRows];
          this.errTexts =
            lang == "vi" ? [...validates.errTexts] : [...validates.errEnTexts];
        }
        this.$vToastify.error(errMsg);
      }
    },
    async getDeliveryCompany() {
      const { data } = await httpClient.post("/get-delivery-company-names");
      this.deliveryCompanyNames = [...data];
    },
    async getShop() {
      if (!this.customer.is_multiple_shop) {
        return false;
      }

      const { data } = await httpClient.post("/shop-all");
      this.shops = [...data];
    },
  },
};
</script>

<style scoped></style>
